<template>
  <div class="navigation-container">
    <Grid :column="5" class="navigation-inner-container">
      <div
        v-for="(navItem, index) in navItems"
        :id="navItem.btnId"
        :key="index"
        :title="navItem.label"
        class="navbar-item"
        @click="beforePolis(navItem)"
        @keypress="beforePolis(navItem)"
      >
        <!-- <nuxt-link :to="`${localePath(navItem.path)}`" @click.native="changeAciveList(navItem.path, navItem.id)"> -->
        <div
          @click="changeAciveList(navItem.path, navItem.id)"
          @keypress="changeAciveList(navItem.path, navItem.id)"
        >
          <Spacer bottom="xxs">
            <navigation
              :name="navItem.id"
              :active="activePath === navItem.id ? true : false"
              :notif="unreaded"
            />
          </Spacer>
          <WebText
            :ink="activePath === navItem.id ? 'label' : 'grayscale'"
            variant="ui-tiny"
            bold
          >
            {{ navItem.label }}
          </WebText>
          <!-- </nuxt-link> -->
        </div>
      </div>
    </Grid>
  </div>
</template>

<script>
import { Text as WebText, Grid, Spacer } from '@qoala/ui';
import Navigation from '@/assets/icons/Navigation';

export default {
  name: 'GeneralNavigation',
  components: {
    WebText,
    Grid,
    Spacer,
    Navigation,
  },
  props: {
    activeMenu: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      notif: false,
      countryCode: 'ID',
    };
  },

  computed: {
    navItems() {
      return [
        {
          id: 'index',
          path: 'index',
          label: this.content.QoalaOnlineHomepage.homeLabel,
        },
        {
          id: 'products',
          btnId: 'navPolicy',
          path: 'products',
          label: this.content.QoalaOnlineHomepage.policyLabel,
        },
        {
          id: 'products-claim',
          btnId: 'navClaim',
          path: this.isLogin ? 'products' : this.postPurchase,
          label: this.content.QoalaOnlineHomepage.claimLabel,
        },
        {
          id: 'notification',
          btnId: 'navNotification',
          path: 'notification',
          label: this.content.QoalaOnlineHomepage.notificationLabel,
        },
        {
          id: 'account',
          btnId: 'navAccount',
          path: 'account',
          label: this.content.QoalaOnlineHomepage.accountLabel,
        },
      ];
    },
    unreaded() {
      return this.notif;
    },
    activePath() {
      const path = (this.$route.name || 'index').split('___')[0];
      if (path === 'products') {
        if (this.activeList === 'claim') return 'products-claim';
        else return 'products';
      }
      return path;
    },
    isLogin() {
      const token = this.$store.getters['user/getUserToken'];
      if (token) {
        return true;
      }
      return false;
    },
    activeList() {
      return this.$store.getters['policy/getActiveList'];
    },
    postPurchase() {
      return 'policy-add';
    },
  },
  async created() {
    await this.$store.dispatch('user/getNotifications', {
      page: 1,
      callback: () => {
        this.setListNotification();
      },
    });
    this.countryCode = this.$store.getters['core/getCountryCode'];
  },
  methods: {
    beforePolis(e) {
      const choosen = this.$store.getters['policy/getActiveList'];
      if (e.id === 'products') {
        this.$store.commit('policy/updateActiveList', choosen || 'policy');
      }
    },
    setListNotification() {
      const listNotif = this.$store.getters['user/getUserNotifList'];
      // eslint-disable-next-line array-callback-return
      listNotif.map((notif) => {
        if (notif.read === null) {
          this.notif = true;
        }
      });
    },
    changeAciveList(path, id) {
      let menu;
      if (id === 'products') menu = 'Policy';
      else if (id === 'products-claim') menu = 'Claim';
      else menu = this.$helper.capitalize(id);
      this.$analytics.pushEvent({
        event: 'Navigation-Bar',
        eventLabel: `Navbar-Click-${menu}`,
      });
      if (path === 'products') {
        if (id === 'products-claim')
          this.$store.commit('policy/updateActiveList', 'claim');
        else this.$store.commit('policy/updateActiveList', 'policy');
      }
      this.$router.push(this.localePath(path));
    },
  },
};
</script>

<style scoped>
@media (min-width: 480px) {
  .navigation-container {
    left: calc(50vw - 240px);
  }
}

@media (max-width: 480px) {
  .navigation-container {
    left: 0;
  }
}

.navigation-container {
  height: 56px;
  width: 100%;
  max-width: 480px;
  z-index: 99;
  bottom: 0;
  overflow: hidden;
  position: fixed;
  background-color: #fff;
  -webkit-transition: all ease 0.5s; /* For Safari 3.1 to 6.0 */
  transition: all ease 0.5s;
  box-shadow: 0 -3px 10px 0 rgba(181, 195, 224, 0.55);
}

.navigation-inner-container {
  margin: 0 auto;
  width: 100%;
  max-width: 480px;
  align-items: center;
  text-align: center;
  height: 100%;
}

.nav-icon {
  height: 32px;
  object-fit: contain;
}

.navbar-item {
  cursor: pointer;
}
.navbar-item a {
  text-decoration: none;
}
</style>
