<template funcitonal>
  <div>
    <svg
      v-if="name === 'index'"
      xmlns="http://www.w3.org/2000/svg"
      width="18.975"
      height="17.669"
      viewBox="0 0 18.975 17.669"
    >
      <g id="Group_2878" data-name="Group 2878" transform="translate(0.001 0)">
        <g id="Group_2877" data-name="Group 2877">
          <path
            id="Path_2405"
            :class="{ active: active }"
            data-name="Path 2405"
            d="M18.657,25.031l-2.876-2.876V18.834a1.088,1.088,0,0,0-2.175,0V19.98l-2.141-2.141a2.861,2.861,0,0,0-3.955,0l-7.19,7.19A1.088,1.088,0,0,0,1.858,26.57l7.19-7.19a.642.642,0,0,1,.88,0l7.191,7.191a1.088,1.088,0,1,0,1.539-1.538Z"
            transform="translate(-0.001 -17.047)"
            class="color"
          />
          <path
            id="Path_2406"
            :class="{ active: active }"
            data-name="Path 2406"
            d="M75.864,127.9a.534.534,0,0,0-.755,0l-6.325,6.323a.535.535,0,0,0-.156.378v4.612a1.96,1.96,0,0,0,1.96,1.96h3.131v-4.849h3.535v4.849h3.131a1.96,1.96,0,0,0,1.96-1.959v-4.612a.533.533,0,0,0-.156-.378Z"
            transform="translate(-65.998 -123.508)"
            class="color-2"
          />
        </g>
      </g>
    </svg>

    <!-- <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.643"
      height="17.583"
      viewBox="0 0 17.643 17.583"
      v-if="name === 'account'"
    >
      <path
        id="Subtraction_2"
        data-name="Subtraction 2"
        d="M16003.825-13282.554a7.975,7.975,0,0,1-7.966-7.966,7.972,7.972,0,0,1,7.966-7.961v3.658h-.077a4.431,4.431,0,0,0-4.424,4.429,4.431,4.431,0,0,0,4.424,4.429,4.434,4.434,0,0,0,4.43-4.429c0-.042,0-.084,0-.125h3.61A7.972,7.972,0,0,1,16003.825-13282.554Zm9.677-9.617h-5.7a4.42,4.42,0,0,0-2.269-2.276v-5.689a7.974,7.974,0,0,1,7.965,7.965Z"
        transform="translate(-15995.86 13300.137)"
        class="color"
        :class="{ active: active }"
      />
    </svg> -->

    <svg
      v-if="name === 'account'"
      xmlns="http://www.w3.org/2000/svg"
      width="19.704"
      height="19.703"
      viewBox="0 0 19.704 19.703"
    >
      <path
        id="Path_2204"
        :class="{ active: active }"
        class="color"
        data-name="Path 2204"
        d="M9.852,0A9.852,9.852,0,1,0,19.7,9.852,9.881,9.881,0,0,0,9.852,0Zm0,2.956a2.9,2.9,0,0,1,2.956,2.956A2.9,2.9,0,0,1,9.852,8.867,2.9,2.9,0,0,1,6.9,5.911,2.9,2.9,0,0,1,9.852,2.956Zm0,13.989a7.187,7.187,0,0,1-5.911-3.153c0-1.97,3.941-3.054,5.911-3.054s5.911,1.084,5.911,3.054A7.188,7.188,0,0,1,9.852,16.945Z"
      />
    </svg>

    <svg
      v-if="name === 'learning'"
      xmlns="http://www.w3.org/2000/svg"
      width="18.42"
      height="19.194"
      viewBox="0 0 18.42 19.194"
    >
      <path
        id="XMLID_49_"
        :class="{ active: active }"
        d="M21.2,16.773v.561a.889.889,0,0,1-.754.881l-.138.509a.634.634,0,0,1-.612.47h-1.37a.634.634,0,0,1-.612-.47l-.134-.509a.893.893,0,0,1-.758-.885v-.561a.539.539,0,0,1,.541-.541h3.3A.545.545,0,0,1,21.2,16.773Zm2.54-7.559A4.7,4.7,0,0,1,22.411,12.5a4.336,4.336,0,0,0-1.161,2.354.782.782,0,0,1-.774.66H17.541a.774.774,0,0,1-.77-.656A4.384,4.384,0,0,0,15.6,12.492a4.728,4.728,0,1,1,8.136-3.278ZM19.543,6.351a.533.533,0,0,0-.533-.533A3.416,3.416,0,0,0,15.6,9.23a.533.533,0,1,0,1.066,0A2.349,2.349,0,0,1,19.01,6.884.531.531,0,0,0,19.543,6.351ZM19.01,2.934a.533.533,0,0,0,.533-.533V.533a.533.533,0,0,0-1.066,0V2.4A.533.533,0,0,0,19.01,2.934ZM12.734,9.21a.533.533,0,0,0-.533-.533H10.333a.533.533,0,0,0,0,1.066H12.2A.531.531,0,0,0,12.734,9.21Zm14.953-.533H25.819a.533.533,0,0,0,0,1.066h1.868a.533.533,0,0,0,0-1.066ZM13.821,13.649,12.5,14.972a.532.532,0,0,0,.75.754L14.571,14.4a.532.532,0,1,0-.75-.754Zm10-8.72a.532.532,0,0,0,.375-.154l1.323-1.323a.533.533,0,0,0-.754-.754L23.445,4.021a.532.532,0,0,0,0,.754A.542.542,0,0,0,23.825,4.929Zm-10-.158a.532.532,0,1,0,.75-.754L13.248,2.694a.533.533,0,1,0-.754.754ZM24.2,13.649a.533.533,0,1,0-.754.754l1.323,1.323a.532.532,0,1,0,.75-.754Z"
        transform="translate(-9.801)"
        class="color"
      />
    </svg>

    <svg
      v-if="name === 'notification'"
      id="Group_3719"
      data-name="Group 3719"
      xmlns="http://www.w3.org/2000/svg"
      width="18.18"
      height="18.971"
      viewBox="0 0 18.18 18.971"
    >
      <path
        id="Path_2402"
        :class="{ active: active }"
        data-name="Path 2402"
        d="M134.324,344.494a3.162,3.162,0,1,1-3.162-3.162A3.162,3.162,0,0,1,134.324,344.494Zm0,0"
        transform="translate(-123.257 -328.685)"
        class="color"
      />
      <path
        id="Path_2403"
        :class="{ active: active }"
        data-name="Path 2403"
        d="M13.444,50.51a5.52,5.52,0,0,1-4.28-7.692A5.514,5.514,0,0,0,2.371,48.2v2.2A5.3,5.3,0,0,1,.484,54.462a1.383,1.383,0,0,0,.9,2.434H14.426a1.383,1.383,0,0,0,.892-2.441A5.293,5.293,0,0,1,13.444,50.51Zm0,0"
        transform="translate(0 -41.087)"
        class="color-2"
      />
      <path
        id="Path_2404"
        :class="{ active: notif }"
        data-name="Path 2404"
        d="M285.237,3.952A3.952,3.952,0,1,1,281.284,0,3.952,3.952,0,0,1,285.237,3.952Zm0,0"
        transform="translate(-267.056)"
        class="notif"
      />
    </svg>

    <svg
      v-if="name === 'products'"
      xmlns="http://www.w3.org/2000/svg"
      width="16.447"
      height="18.229"
      viewBox="0 0 16.447 18.229"
    >
      <g id="Group_2879" data-name="Group 2879" transform="translate(-0.001 0)">
        <path
          id="Path_2407"
          :class="{ active: active && activeList === 'policy' }"
          data-name="Path 2407"
          d="M16.622,2.473,9.339.045a.888.888,0,0,0-.561,0L1.5,2.473a1,1,0,0,0-.661.959c0,10.75,5.088,13.32,7.821,14.7a.888.888,0,0,0,.8,0c2.734-1.38,7.822-3.95,7.822-14.7A1,1,0,0,0,16.622,2.473ZM9.059,16.112c-2.6-1.331-6.138-3.537-6.333-11.942L9.059,2.058l6.332,2.111C15.2,12.574,11.654,14.78,9.059,16.112Z"
          transform="translate(-0.835 0)"
          class="color"
        />
        <path
          id="Path_2408"
          :class="{ active: active && activeList === 'policy' }"
          data-name="Path 2408"
          d="M8.709,9.749a10.909,10.909,0,0,0,1.422,5.566L16.54,8.907l-2.568-.8Z"
          transform="translate(-5.748 -4.701)"
          class="color-2"
        />
        <path
          id="Path_2409"
          :class="{ active: active && activeList === 'policy' }"
          data-name="Path 2409"
          d="M19.027,22.133c1.8-.809,5.185-3.517,5.261-9.143l-7.479,7.479A9.556,9.556,0,0,0,19.027,22.133Z"
          transform="translate(-10.803 -7.395)"
          class="color-2"
        />
      </g>
    </svg>
    <svg
      v-if="name === 'promo'"
      xmlns="http://www.w3.org/2000/svg"
      width="19.979"
      height="20.212"
      viewBox="0 0 19.979 20.212"
    >
      <g id="Group_4096" data-name="Group 4096" transform="translate(0 -0.333)">
        <path
          id="Path_2129"
          :class="{ active: active }"
          data-name="Path 2129"
          d="M409.314,560.8a.6.6,0,1,0-1.112.461l.384.928a.6.6,0,0,0,1.112-.46Zm0,0"
          transform="translate(-395.414 -542.378)"
          class="color"
        />
        <path
          id="Path_2130"
          :class="{ active: active }"
          data-name="Path 2130"
          d="M181.39,13.679a.6.6,0,1,0,1.112-.46l-.384-.928a.6.6,0,0,0-1.112.46Zm0,0"
          transform="translate(-175.311 -11.224)"
          class="color"
        />
        <path
          id="Path_2131"
          :class="{ active: active }"
          data-name="Path 2131"
          d="M408.528,14a.6.6,0,0,0,.786-.326l.384-.928a.6.6,0,0,0-1.112-.46l-.384.928A.6.6,0,0,0,408.528,14Zm0,0"
          transform="translate(-395.414 -11.222)"
          class="color"
        />
        <path
          id="Path_2132"
          :class="{ active: active }"
          data-name="Path 2132"
          d="M182.173,560.474a.6.6,0,0,0-.786.326l-.385.929a.6.6,0,0,0,1.113.46l.384-.928A.6.6,0,0,0,182.173,560.474Zm0,0"
          transform="translate(-175.308 -542.378)"
          class="color"
        />
        <path
          id="Path_2133"
          :class="{ active: active }"
          data-name="Path 2133"
          d="M561.762,408.875l-.828-.343a.6.6,0,0,0-.461,1.112l.828.343a.6.6,0,1,0,.461-1.112Zm0,0"
          transform="translate(-542.616 -395.251)"
          class="color"
        />
        <path
          id="Path_2134"
          :class="{ active: active }"
          data-name="Path 2134"
          d="M15.158,183.761l.829.343a.6.6,0,0,0,.461-1.112l-.829-.343a.6.6,0,0,0-.461,1.112Zm0,0"
          transform="translate(-14.326 -176.508)"
          class="color"
        />
        <path
          id="Path_2135"
          :class="{ active: active }"
          data-name="Path 2135"
          d="M560.93,184.1l.828-.343a.6.6,0,0,0-.461-1.112l-.829.343a.6.6,0,1,0,.461,1.112Zm0,0"
          transform="translate(-542.613 -176.508)"
          class="color"
        />
        <path
          id="Path_2136"
          :class="{ active: active }"
          data-name="Path 2136"
          d="M15.987,408.532l-.829.343a.6.6,0,1,0,.461,1.112l.829-.343a.6.6,0,1,0-.461-1.112Zm0,0"
          transform="translate(-14.326 -395.251)"
          class="color"
        />
        <path
          id="Path_2137"
          :class="{ active: active }"
          data-name="Path 2137"
          d="M315.391,214.434V212.91c0-.3-.111-.43-.372-.43s-.366.129-.366.43v1.523c0,.3.11.429.366.429S315.391,214.735,315.391,214.434Zm0,0"
          transform="translate(-304.83 -205.437)"
          class="color"
        />
        <path
          id="Path_2138"
          :class="{ active: active }"
          data-name="Path 2138"
          d="M172.492,378.38v-1.165c0-.232-.09-.336-.291-.336h-.287v1.837h.287C172.4,378.716,172.492,378.612,172.492,378.38Zm0,0"
          transform="translate(-166.548 -364.64)"
          class="color"
        />
        <path
          id="Path_2139"
          :class="{ active: active }"
          data-name="Path 2139"
          d="M363.422,395.393h.359l-.179-.659Zm0,0"
          transform="translate(-352.077 -381.941)"
          class="color"
        />
        <path
          id="Path_2140"
          :class="{ active: active }"
          data-name="Path 2140"
          d="M19.758,9.968l-2.907-2.4.375-3.779a.606.606,0,0,0-.659-.667L12.832,3.5,10.455.557a.6.6,0,0,0-.932,0L7.147,3.5,3.411,3.118a.606.606,0,0,0-.659.667l.375,3.779L.221,9.968a.613.613,0,0,0,0,.943l2.907,2.4-.375,3.779a.606.606,0,0,0,.659.667l3.736-.379,2.377,2.941a.6.6,0,0,0,.932,0l2.376-2.941,3.736.379a.606.606,0,0,0,.659-.667l-.375-3.779,2.907-2.4A.613.613,0,0,0,19.758,9.968Zm-7.712,4.752-.131-.488h-.775l-.13.484c-.13.422-1,.167-.89-.268l.838-2.781c.128-.421,1-.43,1.135,0h0l.841,2.776C13.042,14.887,12.173,15.134,12.046,14.719ZM8.907,12.764c.429,0,.436.776,0,.776H8.346v.544H9.438c.436,0,.432.849,0,.849h-1.6c-.2,0-.419-.1-.419-.333V11.71c0-.229.218-.334.419-.334h1.6c.441,0,.429.849,0,.849H8.346v.539ZM4.857,11.376h.8a1.087,1.087,0,0,1,1.22,1.189v1.178a1.087,1.087,0,0,1-1.22,1.189h-.8c-.275,0-.419-.165-.419-.329V11.7C4.437,11.541,4.582,11.376,4.857,11.376ZM6.98,10.067V8.638H6.126v1.429c0,.569-1.188.568-1.188,0v-3.7c0-.559,1.188-.566,1.188,0V7.646H6.98V6.368c0-.559,1.187-.568,1.187,0v3.7C8.167,10.639,6.98,10.63,6.98,10.067ZM8.635,9.009V7.468a1.386,1.386,0,0,1,1.554-1.519,1.389,1.389,0,0,1,1.56,1.519V9.009a1.389,1.389,0,0,1-1.56,1.52A1.386,1.386,0,0,1,8.635,9.009ZM12.3,5.948h2.483c.557,0,.554,1.108,0,1.108H14.14v3.011c0,.568-1.188.569-1.188,0V7.056H12.3C11.752,7.056,11.746,5.948,12.3,5.948Zm2.919,8.135c.424,0,.423.849,0,.849H13.8c-.2,0-.42-.1-.42-.333V11.7c0-.438.929-.443.929,0v2.378Zm0,0"
          transform="translate(0 0)"
          class="color"
        />
      </g>
    </svg>
    <svg
      v-if="name.split('-')[0] === 'products' && name.split('-')[1] === 'claim'"
      xmlns="http://www.w3.org/2000/svg"
      width="18.875"
      height="18.874"
      viewBox="0 0 18.875 18.874"
    >
      <path
        id="prefix__Claim_2"
        :class="{ active: active && activeList === 'claim' }"
        d="M18.9 8.983A9.438 9.438 0 108.983 18.9a9.633 9.633 0 005.761-1.594A9.306 9.306 0 0018.9 8.983zM14.415 10.3a.812.812 0 01-.812.812h-1.642a.812.812 0 00-.812.812v1.642a.812.812 0 01-.812.812H8.615a.812.812 0 01-.812-.812v-1.642a.812.812 0 00-.812-.812H5.349a.812.812 0 01-.812-.812V8.577a.812.812 0 01.812-.812h1.643a.812.812 0 00.808-.811V5.312a.812.812 0 01.815-.812h1.723a.812.812 0 01.812.812v1.642a.812.812 0 00.812.812H13.6a.812.812 0 01.812.812z"
        data-name="Claim 2"
        transform="translate(-.037 -.037)"
        class="color-2"
      />
    </svg>
  </div>
</template>

<script>
import qoalaStorage from '~/core/Storage';

export default {
  name: 'IconNavigation',

  props: {
    name: {
      type: String,
      default: 'index'
    },
    active: {
      type: Boolean,
      default: false
    },
    notif: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    activeList() {
      this.$store.commit('policy/updateActiveList', qoalaStorage.getItem('activeList'));
      return this.$store.getters['policy/getActiveList'];
    }
  }
};
</script>

<style lang="scss" scoped>
.color {
  fill: #b3caef;
  &.active {
    fill: #ffa000;
  }
}
.color-2 {
  fill: #c4d7f5;
  &.active {
    fill: #ffc107;
  }
}
.notif {
  fill: #ebf3ff;
  &.active {
    fill: #ff6400;
  }
}
</style>
